import * as React from "react";
import Layout from "../components/layout/layout";
import "../theme/style.scss";
import Image404 from "../images/404.svg";
import Seo from "../components/basics/seo/seo.jsx";
import Button from "../components/basics/button/button";
import ButtonContainer from "../components/basics/button/buttonContainer";
// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title={"Page not found"} />
      <div id="content">
        <div className="page-not-found-container">
          <div className="page-not-found">
            <img src={Image404} alt="" />
            <p>
              <b>Page not found</b>
            </p>
            <p>
              The page you are looking for might have been removed had its name
              changed or is temporarily unavailable.
            </p>
            <ButtonContainer>
              <Button text={"Back to the homepage"} url={"/"} />
            </ButtonContainer>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
